import React, { useEffect, useState, useMemo } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
  useSearchParams,
} from 'react-router-dom'
import './index.css'
import {
  addDoc,
  collection,
  getFirestore,
  query,
  getDocs,
  where,
} from 'firebase/firestore/lite'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBSfU09Nw6YytLhLvvzbSn9LTOIP34tE80',
  authDomain: 'pesapal-poc.firebaseapp.com',
  projectId: 'pesapal-poc',
  storageBucket: 'pesapal-poc.appspot.com',
  messagingSenderId: '1054623335934',
  appId: '1:1054623335934:web:2e8375a97c1e5d2ff5acaf',
}

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)

const functions = getFunctions()

const buy = httpsCallable(functions, 'buy')

const View = ({ children }) => (
  <div className="w-full min-h-screen flex items-center justify-center">
    {children}
  </div>
)

const Loader = () => (
  <svg
    className="animate-spin w-5 h-5 mr-1"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

const Buy = () => {
  const [loading, setLoading] = useState(false)

  const buyEverythingNow = async () => {
    setLoading(true)

    let docRef

    try {
      docRef = await addDoc(collection(db, 'demo'), {
        status: 'Pending',
        createdAt: new Date(),
        updatedAt: new Date(),
      })
    } catch (e) {
      console.log(e)
    }

    buy({ id: docRef.id }).then(result => {
      window.location = result.data.redirectUrl
    })
  }

  return (
    <View>
      {loading && (
        <div className="text-[#767676] text-sm flex items-center justify-center">
          <Loader />
          <div>Processing…</div>
        </div>
      )}
      {!loading && (
        <div>
          <div>
            <button
              onClick={buyEverythingNow}
              className="no-underline bg-purple-500 hover:bg-purple-700 text-white animation block py-2 px-3"
            >
              Buy everything now
            </button>
          </div>
          <div className="text-[#767676] text-center pt-2 text-sm">
            with{' '}
            <a
              href="https://pesapal.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-purple-700"
            >
              PesaPal
            </a>
          </div>
        </div>
      )}
    </View>
  )
}

const Confirmation = () => {
  const [status, setStatus] = useState('Pending')

  let [params] = useSearchParams()

  useEffect(() => {
    const handleOrderTrackingId = async () => {
      const orderTrackingId = params.get('OrderTrackingId')

      if (orderTrackingId) {
        const order = query(
          collection(db, 'demo'),
          where('orderTrackingId', '==', orderTrackingId)
        )

        const snapshot = await getDocs(order)

        snapshot.forEach(doc => {
          const data = doc.data()

          setStatus(data.status)
        })
      }
    }

    handleOrderTrackingId()
  }, [params])

  const className = useMemo(() => {
    switch (status) {
      case 'Completed':
        return 'text-green-700'
      case 'Failed':
        return 'text-red-700'
      default:
        return 'text-[#767676]'
    }
  }, [status])

  return (
    <View>
      <div>
        <div>
          Thank you for your order, expect{' '}
          <span className="inline-block animation text-purple-700">
            everything
          </span>{' '}
          soon
        </div>
        <div className="text-[#767676] text-center pt-6 text-sm flex items-center justify-center h-5">
          {status === 'Pending' && <Loader />}
          <div className={`leading-none ${className}`}>
            {status
              .replace(
                'Pending',
                'Processing payment… (please give it a minute)'
              )
              .replace('Completed', '✅ Payment received')
              .replace('Failed', '❌ Payment failed')}
          </div>
        </div>
      </div>
    </View>
  )
}

const router = createBrowserRouter([
  {
    path: '/confirmation',
    element: <Confirmation />,
  },
  {
    path: '/',
    element: <Buy />,
  },
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
)
